import React from "react";

const HODL = () => (
  <section className="word-description-container">
    <h1>HODL</h1>
    <p>
      <b>'Hold On for Dear Life'.</b> Termen refererar till att att hålla i
      NFT's trots upp och nedgångar av priset.
    </p>
  </section>
);

export default HODL;
