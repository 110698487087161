import React from "react";

const Mint = () => (
  <section className="word-description-container">
    <h1>Mint</h1>
    <p>Mint refererar till skapandet av ett nytt NFT token.</p>
  </section>
);

export default Mint;
