import React from "react";
// import { Link } from "react-router-dom";

const X = () => (
  <section className="words">
    <br />
    <br />
    <h1>X</h1>
    <br />
  </section>
);

export default X;
