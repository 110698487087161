import React from "react";
// import { Link } from "react-router-dom";

const Z = () => (
  <section className="words">
    <br />
    <br />
    <h1>Z</h1>
    <br />
  </section>
);

export default Z;
