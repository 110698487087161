import React from "react";
// import { Link } from "react-router-dom";

const Q = () => (
  <section className="words">
    <br />
    <br />
    <h1>Q</h1>
    <br />
  </section>
);

export default Q;
