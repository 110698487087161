import React from "react";

const SER = () => (
  <section className="word-description-container">
    <h1>SER</h1>
    <p>Ser är en avsiktlig felstavning av ordet 'Sir'.</p>
  </section>
);

export default SER;
